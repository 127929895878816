<template>
  <component
    :is="componentTag"
    :class="['btn', ...classComputed]"
  >
    <template v-if="iconOnly && iconName">
      <Icon
        :name="iconName"
        :size="iconSize"
      />
    </template>

    <span
      v-if="$slots.iconPrepend"
      class="btn__icon"
    >
      <slot
        name="iconPrepend"
        :iconSize="iconSize"
      />
    </span>

    <span
      v-if="$slots.default"
      class="btn__text"
    >
      <slot />
    </span>

    <span
      v-if="$slots.icon"
      class="btn__icon"
    >
      <slot
        name="icon"
        :iconSize="iconSize"
      />
    </span>

    <slot name="append" />
  </component>
</template>

<script lang="ts" setup>
import { LocaleLink } from '@shared/LocaleLink';
import { Icon } from '@shared/Icon';

interface IProps {
  size?: 'sm' | 'lg' | 'xl';
  variant?: 'primary' | 'secondary' | 'gold' | 'link' | 'catalog';
  square?: boolean;
  round?: boolean;
  block?: boolean;
  outline?: boolean;
  loading?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  iconLeft?: boolean;
  iconOnly?: boolean;
  iconName?: string;
  iconSize?: string;
}

const props = defineProps<IProps>();

const classComputed = computed(() => {
  return [
    {
      'btn--block': props.block,
      'btn--square': props.square,
      'btn--round': props.round,
      'btn--outline': props.outline,
      'btn--loading': props.loading,
      'btn--iconleft': props.iconLeft,
      'btn--disabled': props.disabled,
      'btn--readonly': props.readonly,
      'btn--icononly': props.iconOnly,
    },
    props.size && `btn--${props.size}`,
    props.variant && `btn--${props.variant}`,
  ];
});

const $attrs = useAttrs();

const componentTag = computed(() => {
  const hasTo = 'to' in $attrs && !!$attrs.to;
  const hasHref = 'href' in $attrs && !!$attrs.href;

  return hasTo ? LocaleLink : hasHref ? 'a' : 'button';
});
</script>

<style lang="scss" src="./Button.scss" />
